.dot {
  height: 20px;
  width: 20px;
}

.schoolTitle {
  line-height: 22px;
  font-size: 30px;
}

.schoolTitle, .timeframe, .degree {
  font-family: 'Futura'
}

.edHeader {
  align-items: center;
  display: flex;
}

.timeframe {
  color: gray;
  margin-left: 10px;
  font-size: 12px;
}

.finalItem .ant-timeline-item-tail {
  display: none
}

.degree {
  font-size: 18px;
}