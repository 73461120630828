.App {
  padding: 40px 50px;
  background-image: url('/images/right_left_brain_2.jpeg');
  background-position: center;
  background-size: center;
  display: flex;
  flex-direction: column;
  
  // comment below for PDF
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.orientationMessage {
  margin-top: 200px;
  text-align: center;
  font-size: 28px;
  font-family: 'Futura';
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.pdfArrow {
  position: fixed;
  right: 0;
  bottom: 0;
  font-size: 36px;
  margin: 0 25px 20px 0;
}

.pdfLink {
  text-decoration: none;
  color: grey;

  :hover {
    color: plum;
  }
}

.rotateIcon {
  text-align: center;
  margin-top: 50px;
  font-size: 50px;
}

.summary, .skill {
  font-family: 'Futura';
}

.summary {
  margin-bottom: -5px;
  font-size: 16px;
}

.skillsList {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center
}

 .skill {
  margin: 5px;
  font-size: 14px;

 }

.sectionHeader {
  color: rgb(53, 51, 51);
  font-size: 80px;
  margin: -81px 0 0 -40px;
  opacity: 0.4;
  font-family: 'Futura'
}

.divider {
  height: 2px;
  margin: 20px 0 20px 0;
}

.topDivider {
  height: 2px;
  margin: 40px 0 20px 0
}

.skillsDividerMobile {
  margin-top: 140px;
}

.sectionContainer {
  margin-bottom: 35px;
}

.menu {
  position: fixed;
  right: 50px;
}

.ant-timeline-item-tail:last-child {
  display: none
}
