.header {
  display: flex;
  width: 100%;
  height: 80px;
  margin-top: 30px; 
}

.dockedHeader {
  justify-content: center;
  flex-direction: column;
}

.mobileHeader {
  justify-content: space-around;
  align-items: baseline;
  margin-bottom: -60px;
  margin-top: -20px;
}

.headline {
  text-align: center;
  left: 0;
  line-height: 22px;
  font-family: 'Futura'
 }

 .dockedHeadline{
  font-size: 80px;
 }

 .mobileHeadline {
    font-size: 20px;
 }

 .contact {
  display: flex;
  justify-content: center;
}

.role {
  text-align: center;
  font-family: 'Futura';
  font-size: 16px;
}

.email, .phone, .website, .github {
 font-size: 14px;
 padding: 0 10px 0 5px;
 color: black;
 font-family: 'Futura';
}

.website, .github {
  text-decoration: none;
  font-family: 'Futura';
}

.emailIcon, .phoneIcon, .websiteIcon, .githubIcon, .linkedinIcon {
  height: 8px;
  padding-top: 5px;
}
