.dot {
  height: 20px;
  width: 20px;
}

.previousPosition, .primaryPosition{
  align-items: center;
  display: flex;
}

.timeframe {
  font-size: 12px;
  color: gray;
  margin-left: 10px;
}

.primaryPosition {
  margin-bottom: -5px;
  font-size: 18px;
}

.previousPosition {
  margin: 0 0 -10px 0;
  font-size: 14px;
}

.descSection {
  margin: 10px 0 0 -10px;
}

.companyTitle, .previousPosition, .previousPosition, .timeframe {
  font-family: 'Futura';
}

.companyTitle {
  line-height: 22px;
  font-size: 30px;
}

.descSection, .mainPoint, .subPoint {
  font-family: 'Futura';
  font-weight: 100;
  font-size: 16px;
}


.finalItem {
  margin-bottom: -20px;

  .ant-timeline-item-tail {
    display: none
  }
  
}

.ant-collapse, .ant-collapse-item, .ant-collapse-header, .ant-collapse-content {
  background: transparent;
  border: none;
  border-bottom: none !important;
}
